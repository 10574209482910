import "./Footer.scss"

export default function Footer() {
    return (
        <footer>
            <div className="cities">
                <div className="column">
                    <a href="/boguchar/">Такси Богучар</a>
                    <a href="/bugaevka/">Такси КПП Бугаевка</a>
                    <a href="/millerovo/">Такси Миллерово</a>
                    <a href="/novobelaya/">Такси КПП Новобелая</a>
                </div>
                <div className="column">
                    <a href="/rossosh/">Такси Россошь</a>
                    <a href="/rovenki/">Такси Ровеньки</a>
                    <a href="/valuyki/">Такси Валуйки</a>
                    <a href="/voronezh/">Такси Воронеж</a>
                </div>
            </div>
            <span>© Fleeka 2024</span>
        </footer>
    )
}