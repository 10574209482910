import React from "react"
import Helmet from "react-helmet"
// import Leaflet from "leaflet"

import "../../Main/Main.scss"

import TopBanner from "../../../components/TopBanner/TopBanner"
import PriceCalculator from "../../../components/PriceCalculator/PriceCalculator"
import LandingFeatures from "../../../components/LandingFeatures/LandingFeatures"
import LandingHelp from "../../../components/LandingHelp/LandingHelp"

class CityRossosh extends React.Component {
    state = {
        map: null
    }

    componentDidMount() {
        this.moveToHash()

        // const map = Leaflet.map('map', { center: [50.19811244927663, 39.57634098623144], zoom: 8 })
        // map.whenReady(() => setTimeout(() => map.invalidateSize(), 1))
        //
        // Leaflet.tileLayer('https://geo.ati.su/osm_tiles/{z}/{x}/{y}.png', {
        //     maxZoom: 18,
        //     minZoom: 3,
        //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        // }).addTo(map)
        //
        // this.setState({ map })
    }

    moveToHash() {
        const hash = window.location.hash
        if ( hash ) {
            const element = document.querySelector(hash)
            if ( element ) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    render() {
        return (
            <section className="Main City-Rossosh">
                <Helmet>
                    <title>Такси Россошь - Флика такси</title>
                    <meta name="description" content="Такси в Россоши для городских и межгородних поездок! Надёжные водители и комфортные автомобили. Закажите такси сейчас и путешествуйте безопасно и удобно!" />
                    <meta name="keywords" content="Такси, Россошь, городское такси, межгороднее такси, перевозки, надёжное такси, комфортное такси, заказ такси, безопасные поездки." />
                    <meta property="og:title" content="Такси Россошь" />
                    <meta property="og:description" content="Закажите надежное такси в Россоши для городских и междугородних поездок с комфортными автомобилями и опытными водителями. Путешествуйте безопасно и удобно, оставляя заявку прямо сейчас!" />
                    <meta property="og:url" content="https://fleeka.ru/rossosh/" />
                    {/*<link type="text/css" rel="stylesheet" href="//unpkg.com/leaflet/dist/leaflet.css" />*/}
                </Helmet>
                <TopBanner city={"Россошь"} intracity />
                <PriceCalculator />
                {/*<div className="map-wrapper">*/}
                {/*    <div id="map" />*/}
                {/*</div>*/}
                <LandingFeatures />
                <LandingHelp />
            </section>
        )
    }
}

export default CityRossosh