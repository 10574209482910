import React from 'react'
import {hydrate} from "react-dom"
import {createRoot} from "react-dom/client"
import {BrowserRouter} from "react-router-dom"
import './index.css'
import App from './App'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
if ( rootElement.hasChildNodes() ) {
    hydrate(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        rootElement
    )
}
else {
    root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
    )
}