import React from "react"
import Helmet from "react-helmet"
import "../../Main/Main.scss"
import TopBanner from "../../../components/TopBanner/TopBanner"
import PriceCalculator from "../../../components/PriceCalculator/PriceCalculator"
import LandingFeatures from "../../../components/LandingFeatures/LandingFeatures"
import LandingHelp from "../../../components/LandingHelp/LandingHelp"

class CityValuyki extends React.Component {
    componentDidMount() {
        this.moveToHash()
    }

    moveToHash() {
        const hash = window.location.hash
        if ( hash ) {
            const element = document.querySelector(hash)
            if ( element ) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    render() {
        return (
            <section className="Main City-Valuyki">
                <Helmet>
                    <title>Такси Валуйки - Флика такси</title>
                    <meta name="description" content="Такси в Валуйках для межгородних поездок! Надежные перевозки с комфортом и безопасностью. Мы предлагаем услуги такси для поездок внутри города и междугородних поездок с опытными водителями и удобными автомобилями." />
                    <meta name="keywords" content="Такси, Валуйки, межгороднее такси, перевозки, надёжное такси, комфортное такси, заказ такси, безопасные поездки." />
                    <meta property="og:title" content="Такси Валуйки" />
                    <meta property="og:description" content="Закажите надежное такси в Валуйках для междугородних поездок с комфортными автомобилями и опытными водителями. Путешествуйте безопасно и удобно, оставляя заявку прямо сейчас!" />
                    <meta property="og:url" content="https://fleeka.ru/valuyki/" />
                </Helmet>
                <TopBanner city={"Валуйки"} />
                <PriceCalculator />
                <LandingFeatures />
                <LandingHelp />
            </section>
        )
    }
}

export default CityValuyki