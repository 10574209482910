import React from "react"
import Helmet from "react-helmet"
import "../../Main/Main.scss"
import TopBanner from "../../../components/TopBanner/TopBanner"
import PriceCalculator from "../../../components/PriceCalculator/PriceCalculator"
import LandingFeatures from "../../../components/LandingFeatures/LandingFeatures"
import LandingHelp from "../../../components/LandingHelp/LandingHelp"

class CityNovobelaya extends React.Component {
    componentDidMount() {
        this.moveToHash()
    }

    moveToHash() {
        const hash = window.location.hash
        if ( hash ) {
            const element = document.querySelector(hash)
            if ( element ) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    render() {
        return (
            <section className="Main City-Novobelaya">
                <Helmet>
                    <title>Такси Новобелая - Флика такси</title>
                    <meta name="description" content="В Новобелой ваша поездка станет приятным и беззаботным благодаря нашему такси! Опытные водители и комфортные автомобили ждут вас для межгородних поездок." />
                    <meta name="keywords" content="Такси, Новобелая, межгороднее такси, перевозки, надёжное такси, комфортное такси, заказ такси, безопасные поездки." />
                    <meta property="og:title" content="Такси Новобелая" />
                    <meta property="og:description" content="Закажите надежное такси в Новобелой для междугородних поездок с комфортными автомобилями и опытными водителями. Путешествуйте безопасно и удобно, оставляя заявку прямо сейчас!" />
                    <meta property="og:url" content="https://fleeka.ru/novobelaya/" />
                </Helmet>
                <TopBanner city={"КПП Новобелая"} />
                <PriceCalculator />
                <LandingFeatures />
                <LandingHelp />
            </section>
        )
    }
}

export default CityNovobelaya