import "./LandingFeatures.scss"

export default function LandingFeatures() {
    return (
        <section className="LandingFeatures">
            <div className="wrap">
                <div className="title">Преимущества</div>
                <div className="features-list">
                    <div className="features-item">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100.5 100.5">
                            <path d="M61.806,53.522c12.454,0,22.587-10.133,22.587-22.588S74.26,8.346,61.806,8.346h-36.7c-0.829,0-1.5,0.671-1.5,1.5  s0.671,1.5,1.5,1.5h7.358l-0.07,39.176H25.13c-0.829,0-1.5,0.672-1.5,1.5s0.671,1.5,1.5,1.5h7.259l-0.02,11.052h-7.264  c-0.829,0-1.5,0.672-1.5,1.5s0.671,1.5,1.5,1.5h7.258l-0.04,22.577c-0.001,0.829,0.669,1.501,1.498,1.503c0,0,0.001,0,0.002,0  c0.827,0,1.499-0.67,1.5-1.497l0.04-22.583h27.951c0.828,0,1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5H35.37l0.02-11.052H61.806z   M35.464,11.346h26.342c10.8,0,19.587,8.788,19.587,19.589c0,10.8-8.787,19.587-19.587,19.587H35.395L35.464,11.346z" />
                        </svg>
                        <span>Низкие цены на поездки</span>
                    </div>
                    <div className="features-item">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100.353 100.352">
                            <g>
	                            <path d="M49.982,17.015c-18.555,0-33.65,15.096-33.65,33.65s15.096,33.65,33.65,33.65c18.555,0,33.651-15.096,33.651-33.65   S68.537,17.015,49.982,17.015z M49.982,80.708c-16.566,0-30.044-13.477-30.044-30.043s13.478-30.044,30.044-30.044   c16.566,0,30.043,13.478,30.043,30.044S66.548,80.708,49.982,80.708z" />
                                <path d="M58.485,45.198l-7.258,7.258V26.64c0-0.996-0.808-1.803-1.804-1.803s-1.803,0.807-1.803,1.803v30.169   c0,0.119,0.012,0.238,0.036,0.355c0.01,0.051,0.029,0.098,0.044,0.148c0.018,0.063,0.033,0.127,0.059,0.189   c0.025,0.06,0.059,0.114,0.09,0.171c0.025,0.047,0.046,0.095,0.076,0.14c0.132,0.198,0.303,0.368,0.501,0.501   c0.037,0.025,0.077,0.041,0.115,0.062c0.064,0.036,0.126,0.074,0.195,0.103c0.054,0.022,0.111,0.035,0.166,0.052   c0.057,0.017,0.111,0.039,0.17,0.05c0.116,0.023,0.234,0.036,0.352,0.036s0.236-0.012,0.352-0.036   c0.059-0.012,0.114-0.033,0.171-0.051c0.055-0.017,0.111-0.029,0.165-0.051c0.07-0.029,0.134-0.068,0.199-0.105   c0.037-0.021,0.075-0.036,0.111-0.06c0.101-0.067,0.195-0.143,0.279-0.229l10.335-10.335c0.704-0.704,0.704-1.846,0-2.55   C60.331,44.493,59.19,44.493,58.485,45.198z" />
                                <path d="M49.983,9.044c-22.95,0-41.621,18.671-41.621,41.622s18.671,41.621,41.621,41.621c22.95,0,41.622-18.671,41.622-41.621   S72.933,9.044,49.983,9.044z M49.983,88.679c-20.961,0-38.015-17.053-38.015-38.014c0-20.961,17.053-38.015,38.015-38.015   s38.014,17.054,38.014,38.015C87.997,71.626,70.944,88.679,49.983,88.679z" />
                            </g>
                        </svg>
                        <span>Быстрая подача такси</span>
                    </div>
                    <div className="features-item">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100.176 100.178">
                            <path d="M86.892,21.638H70.136l-1.567-5.716c-0.111-0.409-0.392-0.751-0.77-0.942c-0.378-0.191-0.82-0.214-1.214-0.062L48.733,21.77  c-0.187-0.084-0.393-0.132-0.611-0.132H26.666c-0.407,0-0.797,0.166-1.08,0.459L12.364,35.809c-0.286,0.225-0.479,0.541-0.546,0.892  c-0.008,0.036-0.01,0.074-0.015,0.111c-0.005,0.048-0.013,0.094-0.014,0.143c-0.001,0.019-0.006,0.038-0.006,0.057c0,0,0,0,0,0  c0,0.001,0,0.002,0,0.004V83.86c0,0.828,0.671,1.5,1.5,1.5H80.2c0.828,0,1.5-0.672,1.5-1.5v-5.188h5.191c0.828,0,1.5-0.672,1.5-1.5  V23.138C88.392,22.309,87.72,21.638,86.892,21.638z M70.832,35.517h-49.54l44.822-17.206L70.832,35.517z M27.303,24.638h13.959  l-22.161,8.507L27.303,24.638z M78.7,82.36H14.783V38.517H78.7V82.36z M85.392,75.672H81.7V37.017c0-0.829-0.672-1.5-1.5-1.5h-6.259  l-2.983-10.879h14.433V75.672z" />
                        </svg>
                        <span>Оплата заказа любым доступным способом</span>
                    </div>
                </div>
            </div>
        </section>
    )
}