import {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {HashLink} from "react-router-hash-link"

import "./Header.scss"

export default function Header() {
    const [showMobileLinks, setShowMobileLinks] = useState(false)

    useEffect(() => {
        if (showMobileLinks) {
            document.querySelector("body").classList.add("overflow-hidden")
        }
        else {
            document.querySelector("body").classList.remove("overflow-hidden")
        }
    }, [showMobileLinks])

    const moveToHash = (name) => {
        const hash = window.location.hash.replace("#", "")
        if ( hash ) {
            const element = document.querySelector(`#fa_${hash}`)
            if ( element ) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    return (
        <nav className="Header navbar navbar-default">
            <Link to="/" className="navbar__brand">
                <img height="50px" src="/logo/fleeka-logo-new.png" alt="logo" />
            </Link>
            <div className="navbar__links">
                <HashLink smooth to="/#help" className="navbar__link">
                    <span>Помощь</span>
                </HashLink>
                <Link to="/policy" className="navbar__link">
                    <span>Пользовательское соглашение</span>
                </Link>
            </div>
            {showMobileLinks &&
                <div className="navbar__mobile-links">
                    <div className="links" onClick={() => setShowMobileLinks(false)}>
                        <Link to="/" className="navbar__link">
                            <span>Главная</span>
                        </Link>
                        <HashLink to="/#help" className="navbar__link" onClick={() => moveToHash("help")}>
                            <span>Помощь</span>
                        </HashLink>
                        <Link to="/policy" className="navbar__link">
                            <span>Пользовательское соглашение</span>
                        </Link>
                    </div>
                </div>
            }
            <div className="navbar__mobile-burger" onClick={() => setShowMobileLinks(!showMobileLinks)}>
                {!showMobileLinks &&
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">
                        <path d="M 5 9 L 5 11 L 45 11 L 45 9 L 5 9 z M 5 24 L 5 26 L 45 26 L 45 24 L 5 24 z M 5 39 L 5 41 L 45 41 L 45 39 L 5 39 z" />
                    </svg>
                }
                {showMobileLinks &&
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                        <path d="M 7.7070312 6.2929688 L 6.2929688 7.7070312 L 23.585938 25 L 6.2929688 42.292969 L 7.7070312 43.707031 L 25 26.414062 L 42.292969 43.707031 L 43.707031 42.292969 L 26.414062 25 L 43.707031 7.7070312 L 42.292969 6.2929688 L 25 23.585938 L 7.7070312 6.2929688 z"></path>
                    </svg>
                }
            </div>
        </nav>
    )
}