import "./Policy.scss"

export default function Policy() {
    return (
        <section className="Policy">
            <center>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ FLEEKA.RU</center><br />
            <br />
            Дата вступления в силу: 14.03.2024<br />
            <br />
            <br />
            Данный документ представляет собой Пользовательское соглашение (далее – "Соглашение") между вами (далее – "Пользователь") и [Название компании] (далее – "Компания"), регулирующее использование сервиса междугороднего такси (далее – "Сервис"). Прошу вас внимательно ознакомиться с условиями настоящего Соглашения перед использованием Сервиса.<br />
            <br />
            <br />
            1. ОПРЕДЕЛЕНИЕ УСЛУГ<br />
            <br />
            1.1. Сервис предоставляет услуги междугороднего такси, обеспечивая Пользователям доступ к транспортным средствам и водителям для перевозки между городами.<br />
            <br />
            <br />
            2. ОПЛАТА И ВОЗНАГРАЖДЕНИЕ<br />
            <br />
            2.1. Оплата за услуги междугороднего такси производится в соответствии с тарифами, предоставленными Компанией.<br />
            <br />
            2.2. Компания вправе вносить изменения в тарифы без предварительного уведомления Пользователей.<br />
            <br />
            <br />
            3. ОТМЕНА И ЗАКЛЮЧЕНИЕ СДЕЛКИ<br />
            <br />
            3.1. Пользователь вправе отменить заказ в соответствии с правилами, установленными Компанией.<br />
            <br />
            3.2. Компания вправе отказать в предоставлении услуги без объяснения причин.<br />
            <br />
            <br />
            4. ОБЯЗАТЕЛЬСТВА И ОТВЕТСТВЕННОСТЬ СТОРОН<br />
            <br />
            4.1. Пользователь обязуется использовать Сервис с соблюдением всех применимых законов и правил.<br />
            <br />
            4.2. Компания несет ответственность за качество предоставляемых услуг, а также за сохранность личной информации Пользователя.<br />
            <br />
            <br />
            5. КОНФИДЕНЦИАЛЬНОСТЬ<br />
            <br />
            5.1. Компания обязуется соблюдать конфиденциальность данных Пользователя в соответствии с положениями Политики конфиденциальности.<br />
            <br />
            <br />
            6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ<br />
            <br />
            6.1. Настоящее Соглашение может быть изменено Компанией без предварительного уведомления Пользователей.<br />
            <br />
            6.2. В случае возникновения споров, стороны обязуются разрешать их путем переговоров.<br />
            <br />
            <br />
            Пользуясь Сервисом, Пользователь выражает свое согласие с условиями настоящего Соглашения. Если вы не согласны с этими условиями, прекратите использование Сервиса.<br />
            <br />
            <br />
            <center>
            FLEEKA<br />
            help@fleeka.ru<br />
            Дата последнего изменения: 14.03.2024
            </center>
        </section>
    )
}