import Helmet from "react-helmet"
import "./404.scss"

export default function Page404() {
    return (
        <div className="Page404">
            <Helmet>
                <title>Страница 404 - не найдена</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="text">
                <span>Опаньки! Страница не найдена</span>
            </div>
        </div>
    )
}