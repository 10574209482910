import React, {useRef, useState} from "react"
const AutocompleteItem = (props) => {
    if (!props.data.text) {
        return null
    }

    const label = props.data.text
    return (
        <div className="item" onClick={() => props.onClick(label)}>
            <svg className="icon icon--location" width="20" height="20" viewBox="0 0 20 20" stroke="#000" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 11.192a2.6 2.6 0 1 0 0-5.2 2.6 2.6 0 0 0 0 5.2z" strokeWidth="1.5" />
                <path d="M3.017 7.075C4.658-.142 15.35-.133 16.983 7.083c.959 4.233-1.675 7.817-3.983 10.034a4.328 4.328 0 0 1-6.008 0c-2.3-2.217-4.934-5.809-3.975-10.042z" strokeWidth="1.5" />
            </svg>
            <span>{label}</span>
        </div>
    )
}

const InputField = (props) => {
    const index = props.index
    const placeholder = props.placeholder
    const onChange = props.onChange

    const [route, setRoute] = useState({ text: "" })
    const [routeAutocomplete, setRouteAutocomplete] = useState([])
    const [showAutocomplete, setShowAutocomplete] = useState(false)

    const inputRef = useRef()

    const onFocusInput = () => {
        inputRef?.current?.focus()
    }

    async function getAutocomplete() {
        const text = route.text
        if ( text.length < 3 || text.length > 20 ) {
            setRouteAutocomplete([])
            return null
        }

        const url = `https://api-fleeka.fleeka.ru/?query=${text}`
        const data = await fetch(url)
        return await data.json()
    }

    window[`forceChange_${index}`] = function(value) {
        setRoute(value)
        onChange(value)
    }
    const onChangeRoute = (event) => {
        setRoute({ text: event.target.value })
        setShowAutocomplete(true)
        getAutocomplete()
            .then(result => result && setRouteAutocomplete(result))
            .catch(error => console.error(error))
    }

    const onSelectAutocompleteItem = (item) => {
        onChange(item)
        setRoute(item)
        setRouteAutocomplete([])
    }

    const routeAutocompleteItems = routeAutocomplete.map((item, i) =>
        <AutocompleteItem
            key={i}
            data={item}
            onClick={() => onSelectAutocompleteItem(item)}
        />
    )

    return (
        <div className="input" onClick={onFocusInput}>
            <input
                ref={inputRef}
                type="text"
                value={route.text}
                placeholder={placeholder}
                onChange={onChangeRoute}
            />
            <label htmlFor="calculator_input_from">Откуда</label>
            {showAutocomplete &&
                <div className="input-autocomplete-items">
                    {routeAutocompleteItems}
                </div>
            }
        </div>
    )
}

export default InputField