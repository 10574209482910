import {Route, Routes} from "react-router-dom"

import './App.scss'

import Header from "./components/Header/Header"
import Main from "./routes/Main/Main"
import CityBoguchar from "./routes/cities/Boguchar/Boguchar"
import CityBugaevka from "./routes/cities/Bugaevka/Bugaevka"
import CityMillerovo from "./routes/cities/Millerovo/Millerovo"
import CityNovobelaya from "./routes/cities/Novobelaya/Novobelaya"
import CityRossosh from "./routes/cities/Rossosh/Rossosh"
import CityRovenki from "./routes/cities/Rovenki/Rovenki"
import CityValuyki from "./routes/cities/Valuyki/Valuyki"
import CityVoronezh from "./routes/cities/Voronezh/Voronezh"
import Policy from "./routes/Policy/Policy"
import Footer from "./components/Footer/Footer"
import Page404 from "./routes/404/404"

export default function App() {
    return (
        <div className="App">
            <Header />
            <Routes>
                <Route element={<Main />} path="/" />
                <Route element={<CityBoguchar />} path="/boguchar/" />
                <Route element={<CityBugaevka />} path="/bugaevka/" />
                <Route element={<CityMillerovo />} path="/millerovo/" />
                <Route element={<CityNovobelaya />} path="/novobelaya/" />
                <Route element={<CityRossosh />} path="/rossosh/" />
                <Route element={<CityRovenki />} path="/rovenki/" />
                <Route element={<CityValuyki />} path="/valuyki/" />
                <Route element={<CityVoronezh />} path="/voronezh/" />
                <Route element={<Policy />} path="/policy/" />
                <Route element={<Page404 />} path="*" />
            </Routes>
            <Footer />
        </div>
    )
}