import React from "react"
import "./Main.scss"
import TopBanner from "../../components/TopBanner/TopBanner"
import PriceCalculator from "../../components/PriceCalculator/PriceCalculator"
import LandingFeatures from "../../components/LandingFeatures/LandingFeatures"
import LandingHelp from "../../components/LandingHelp/LandingHelp"

class Main extends React.Component {
    componentDidMount() {
        this.moveToHash()
    }

    moveToHash() {
        const hash = window.location.hash
        if ( hash ) {
            const element = document.querySelector(hash)
            if ( element ) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    render() {
        return (
            <section className="Main">
                <TopBanner />
                <PriceCalculator />
                <LandingFeatures />
                <LandingHelp />
            </section>
        )
    }
}

export default Main