import React from "react"
import Helmet from "react-helmet"
import "../../Main/Main.scss"
import TopBanner from "../../../components/TopBanner/TopBanner"
import PriceCalculator from "../../../components/PriceCalculator/PriceCalculator"
import LandingFeatures from "../../../components/LandingFeatures/LandingFeatures"
import LandingHelp from "../../../components/LandingHelp/LandingHelp"

class CityMillerovo extends React.Component {
    componentDidMount() {
        this.moveToHash()
    }

    moveToHash() {
        const hash = window.location.hash
        if ( hash ) {
            const element = document.querySelector(hash)
            if ( element ) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    render() {
        return (
            <section className="Main City-Millerovo">
                <Helmet>
                    <title>Такси Миллерово - Флика такси</title>
                    <meta name="description" content="Такси в Миллерово для межгородних поездок! Надёжные водители и комфортные автомобили. Закажите такси сейчас и путешествуйте безопасно и удобно!" />
                    <meta name="keywords" content="Такси, Миллерово, межгороднее такси, перевозки, надёжное такси, комфортное такси, заказ такси, безопасные поездки." />
                    <meta property="og:title" content="Такси Миллерово" />
                    <meta property="og:description" content="Закажите надежное такси в Миллерово для междугородних поездок с комфортными автомобилями и опытными водителями. Путешествуйте безопасно и удобно, оставляя заявку прямо сейчас!" />
                    <meta property="og:url" content="https://fleeka.ru/millerovo/" />
                </Helmet>
                <TopBanner city={"Миллерово"} />
                <PriceCalculator />
                <LandingFeatures />
                <LandingHelp />
            </section>
        )
    }
}

export default CityMillerovo