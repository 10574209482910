import React, {useState} from "react"
import classNames from "classnames"
import "./PriceCalculator.scss"
import InputField from "./InputField/InputField"

export default function PriceCalculator() {
    const [tariff, setTariff] = useState(0)

    const [routes, setRoutes] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [resultData, setResultData] = useState(null)

    const onSelectTariff = (i) => {
        setTariff(i)
    }

    const onChangeRoute = (index, value) => {
        setRoutes(x => {
            x[index] = value
            return x
        })
    }

    const swapRoutes = () => {
        const reversed = routes.reverse()
        for (const index in routes) {
            const routeReversed = reversed[index]
            window[`forceChange_${index}`](routeReversed)
        }
    }

    async function getDistanceAndPrice() {
        const cities = routes.map(x => x.id).join(";")
        const citiesNames = routes.map(x => x.text).join(";")

        const url = `https://api-distance.fleeka.ru/?tariff=${tariff}&cities=${cities}&cities_names=${citiesNames}`
        const data = await fetch(url)
        return await data.json()
    }

    const onGetPrice = async () => {
        if (routes.length < 2) {
            return null
        }

        setIsLoading(true)
        getDistanceAndPrice()
            .then(result => {
                result.from = routes[0]?.text ?? ""
                result.to = routes[routes.length - 1]?.text ?? ""
                setResultData(result)
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }

    const resetAll = () => {
        for (const index in routes) {
            // reset all inputs
            window[`forceChange_${index}`]({ text: "" })
        }
        setIsLoading(false)
        setResultData(null)
    }

    return (
        <section className="PriceCalculator">
            <div className={classNames("calculator-title", { hide: resultData })}>
                Рассчитать цену поездки
            </div>
            <div className={classNames( "calculator-tariffs", { hide: !!resultData } )}>
                <div className={classNames("tariff econom", { active: tariff === 0 })} onClick={() => onSelectTariff(0)}>
                    <span>Эконом</span>
                    <img src="/tariffs/taxi-econom-no-bg.png" alt="Эконом" />
                </div>
                <div className={classNames("tariff comfort-plus", { active: tariff === 1 })} onClick={() => onSelectTariff(1)}>
                    <span>Комфорт+</span>
                    <img src="/tariffs/taxi-comfort-no-bg.png" alt="Комфорт+" />
                </div>
                <div className={classNames("tariff minivan", { active: tariff === 2 })} onClick={() => onSelectTariff(2)}>
                    <span>Минивэн</span>
                    <img src="/tariffs/taxi-minivan-no-bg.png" alt="Минивэн" />
                </div>
            </div>
            <div className={classNames("calculator-form", { hide: resultData })}>
                <InputField
                    index={0}
                    placeholder="Откуда (город)"
                    onChange={value => onChangeRoute(0, value)}
                />
                <button className="button-calculator button-swap" onClick={swapRoutes}>
                    <svg className="icon icon--sm icon--center" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                        <path fill="#6C7C8C" fillOpacity=".5" d="M12.01 3H0v2h12.01v3L16 4l-3.99-4v3zM3.99 13H16v-2H3.99V8L0 12l3.99 4v-3z" />
                    </svg>
                </button>
                <InputField
                    index={1}
                    placeholder="Куда (город)"
                    onChange={value => onChangeRoute(1, value)}
                />
                <button className="button-calculator button-submit" onClick={onGetPrice}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19">
                        <path fill="#fff" fillRule="evenodd" d="M6.279 11.95h-.858L0 17.38 1.619 19l5.431-5.42v-.859l.294-.304a7.03 7.03 0 0 0 4.595 1.705 7.061 7.061 0 1 0-7.061-7.06 7.03 7.03 0 0 0 1.705 4.594l-.304.294zm10.549-4.89a4.882 4.882 0 0 1-4.889 4.889A4.882 4.882 0 0 1 7.051 7.06a4.882 4.882 0 0 1 4.888-4.888 4.882 4.882 0 0 1 4.889 4.888z" clipRule="evenodd" />
                    </svg>
                    Узнать цену
                </button>
            </div>
            {isLoading &&
                <div className="loading-spinner">
                    <svg className="spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                </div>
            }
            {resultData &&
                <React.Fragment>
                    <div className="result-back" onClick={resetAll}>
                        <span>Указать другие данные</span>
                    </div>
                    <div className="result-data">
                        <div className="routes">
                            <div className="route from">
                                <span>Откуда</span>
                                <span>{resultData.from}</span>
                            </div>
                            <div className="line">
                                <div className="distance">
                                    ~ {resultData.distance} км
                                </div>
                            </div>
                            <div className="route to">
                                <span>Куда</span>
                                <span>{resultData.to}</span>
                            </div>
                        </div>
                        <div className="price">
                            <span>Стоимость поездки</span><br />
                            <span>~ {resultData.price} Р</span>
                        </div>
                        <a href="tel:+79507007071" className="classic-button color-yellow button-order">Заказать</a>
                    </div>
                </React.Fragment>
            }
        </section>
    )
}