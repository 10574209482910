import React from "react"
import "./TopBanner.scss"
import ImgTelegramLogo from "../../assets/img/telegram-logo.svg"
import ImgWhatsappLogo from "../../assets/img/whatsapp-logo.svg"

export default function TopBanner(props) {
    const city = props.city
    const intracity = props.intracity

    const topText = city
        ? (
            <h1>
                {intracity &&
                    <React.Fragment>
                        Такси {city} - по городу и межгород
                    </React.Fragment>
                }
                {!intracity &&
                    <React.Fragment>
                        Такси {city} межгород
                    </React.Fragment>
                }
            </h1>
        )
        : (
            <h1>
                Закажите такси межгород<br/>
                по телефону
            </h1>
        )

    return (
        <section className="TopBanner">
            <div className="TopBanner__content">
                <div className="phone-call">
                    {topText}
                    <a href="tel:+79507007071" className="classic-button color-yellow">8-(950)-700-70-71</a>
                </div>
                <div className="socials-wrapper">
                    <div className="text">Или напишите нам в</div>
                    <a href="https://t.me/taxiFLEEKA" target="_blank" rel="noreferrer">
                        <img height="24px" src={ImgTelegramLogo} alt="Такси Россошь телеграм" />
                        <span>Telegram</span>
                    </a>
                    <div className="text">или</div>
                    <a href="https://wa.me/79507007071" target="_blank" rel="noreferrer">
                        <img height="24px" src={ImgWhatsappLogo} alt="Такси Россошь ватсап" />
                        <span>Whatsapp</span>
                    </a>
                </div>
            </div>
        </section>
    )
}